html {
    overflow-y: scroll;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
